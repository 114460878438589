import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 62",
  week: "Semana 9",
  day: "14",
  month: "may",
  monthNumber: "05",
  date: "2020-05-14",
  path: "/cronologia/semana-09#dia-14-may",
};
const Day62 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En España, el balance del día se salda con el registro de 1.208 nuevos
          positivos y 217 personas fallecidas. Permanecen hospitalizadas 124.225
          personas, 330 más que el día anterior, y 2.551 reciben el alta. El
          número de casos ingresados en la UCI asciende a 11.464, lo que supone
          un aumento de 28 personas.{" "}
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 52,6 %.{" "}
        </ModText>
        <ModGraph name="14-05-desfaseletalidad" alt="Desfase Letalidad" />
        <ModText>
          Los primeros resultados del ENECovid19, estudio sobre la prevalencia
          del virus en nuestro país, muestran que el 5% de la población ha
          desarrollado anticuerpos frente a la enfermedad, lo que significa que
          alrededor de dos millones de personas son inmunes a COVID-19.
        </ModText>
        <ModText>
          Además, en estas primeras muestras (un total de 60.983 personas)
          también se ha observado que existe una marcada variabilidad
          geográfica, con una prevalencia superior en las provincias del centro
          de la península.
        </ModText>
        <ModText>
          Así, Ceuta, Murcia, Melilla, Asturias y Canarias presentan
          prevalencias inferiores al 2%, mientras las Comunidades de Castilla-La
          Mancha y Madrid superan el 10%. En los mapas provinciales, destaca la
          agrupación central de provincias con prevalencias iguales o próximas
          al 10% en el entorno de Madrid.
        </ModText>
        <ModGraph
          name="mapaprovincial-anticuerpos"
          alt="mapaprovincial-anticuerpos"
        />
        <ModText>
          El Ministerio de Sanidad ha publicado un protocolo de actuación en el
          que establece las medidas básicas de seguridad para velar por la{" "}
          <strong>salud de los ciudadanos en las piscinas</strong>. Entrará en
          vigor única y exclusivamente cuando el Plan de transición hacia una
          nueva normalidad establezca que es posible la apertura.
        </ModText>
        <ModImage src="/images/svg/piscina.svg" alt="piscina" />

        <ModText>
          Asimismo, también ha publicado la actualización del documento
          <strong>
            "Instrucciones sobre la realización de pruebas diagnósticas para la
            detección del COVID-19 en el ámbito de las empresas"
          </strong>
          , donde se indica que los servicios de prevención de riesgos laborales
          que realizan actividades sanitarias deben limitar la utilización de
          pruebas diagnósticas a los ámbitos de actuación establecidos.
        </ModText>
        <ModText>
          Este documento, que ha sido trabajado y consensuado con las
          comunidades autónomas, y aprobado por la Ponencia de Salud Laboral,
          recuerda que la prueba PCR debe realizarse a todo caso sospecho de
          infección en las primeras 24 horas.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.mscbs.gob.es/gabinetePrensa/notaPrensa/pdf/ENE-C140520115618104.pdf"
            name="Estudio ENE-COVID19: resultados primera ronda"
          />
          <ReferenceRow
            link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/instruccionesPruebasDiagnosticasEmpresas.pdf"
            name="Instrucciones para la realización de pruebas diagnósticas en el ámbito de las empresas"
          />
          <ReferenceRow
            link="https://www.mscbs.gob.es/gabinetePrensa/notaPrensa/imagenes/13.05140520115613988.jpg"
            name="Mapa provincial de anticuerpos"
          />
          <ReferenceRow
            link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/recomendacionesAperturaPiscinas.pdf"
            name="Recomendaciones apertura piscinas"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day62;
